import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  profilePhoto?: string;
  name?: string;
  email?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  fullName: string;
  email: string;
  getProfilePicture: any
  isDrawerOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderDropdownController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserAccountDetailsId: string = '';
  getUserProfilePictureId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      fullName: '',
      email: '',
      getProfilePicture: '',
      isDrawerOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      if (apiRequestCallId === this.getUserAccountDetailsId) {
        const attributes = responseJson.data.attributes;
        this.setState({
          fullName: attributes.full_name,
          email: attributes.email,
        });
        setStorageData('fullName', attributes.full_name)
        setStorageData('address', attributes.address)
      } else if (apiRequestCallId === this.getUserProfilePictureId) {
        const profileSrc = responseJson.url?.url
        this.setState({
          getProfilePicture: profileSrc,
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   
    this.getUserAccountDetails();
    this.getUserProfilePicture();
  }

  getInitials = () => {
    const name = this.state.fullName?.split('@')[0];
    return name?.charAt(0).toUpperCase();
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  editProfileClick = () => {
    this.props.navigation.navigate('CustomisableEditprofile');
  }

  subscriptionClick = () => {
    this.props.navigation.navigate("Subscription");
  }

  paymentClick = () => {
    this.props.navigation.navigate("PaymentSettings");
  }

  handleLogo = async() => {
      const navMsg = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      navMsg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "SharingDashboard"
      );
      navMsg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(navMsg);
}
  logoutBtn = () => {
    localStorage.removeItem('token');
    window.location.replace("LandingPage");
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getUserAccountDetails = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserAccountDetailsId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  getUserProfilePicture = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfilePictureId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/get_profile_image`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  toggleDrawer = (toggle: boolean) => {
    this.setState({
      isDrawerOpen: toggle
    })
  }
  // Customizable Area End
}