import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    Divider,
    Checkbox,
    IconButton,
    Toolbar,
    Chip
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import MemoriesDashboardController, {
    Props,
} from "./MemoriesDashboardController";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { downDropIcon, iIcon, recipeAddIcon } from "./assets";
import SideDrawer from "../../../components/src/SideDrawer";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomizedSwitches from "../../../components/src/CustomiseSwitch";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";

// Customizable Area End

export default class MemoriesDashboard extends MemoriesDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { isBold, isItalic, isUnderline, alignment, isUppercase } = this.state;
        const fontStyle: React.CSSProperties = {
            fontWeight: isBold ? 'bold' : 'normal',
            fontStyle: isItalic ? 'italic' : 'normal',
            textDecoration: isUnderline ? 'underline' : 'none',
            textAlign: alignment,
            height: "236px",
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            textTransform: isUppercase ? 'uppercase' : 'none',
        };
        return (
            // Customizable Area Start
            <MemoriesDashboardStyle>
                <NavigationMenu navigation={this.props.navigation} id={""}/>
                <Box className="MainContent">
                    <Box className="Header">
                        <HeaderDropdown
                            data-test-id="headerComponent" navigation={this.props.navigation} id={""}
                        />
                    </Box>
                    <Box className="Content">
                        <Typography className="sharingTitle">Memories Dasboard Comming Soon</Typography>
                        <Button style={{ backgroundColor: "skyBlue", color: "#fff", marginTop: 20, textTransform: 'none' }} data-test-id="relativeModalText" onClick={this.handleRelativeModalOpen}>Add Relative</Button>
                        <Modal
                            open={this.state.relativeModal}
                            data-test-id="coverRelativeClose"
                            onClose={this.handleRelativeModalClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                maxWidth: '700px',
                                width: '100%',
                                maxHeight: '80vh',
                                backgroundColor: 'white',
                                boxShadow: "24",
                                padding: '16px',
                                borderRadius: '8px 8px 32px 8px',
                                overflowY: 'auto'
                            }}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography style={{ fontWeight: 700, fontSize: 24, fontFamily: "Poppins", color: "#000" }}>
                                        Add relative
                                    </Typography>
                                    <Button onClick={this.handleRelativeModalClose}><CloseIcon style={{ color: '#44403C' }} /></Button>
                                </Box>

                                <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 400, color: '#57534E', marginTop: 16 }}>
                                    Connect with your family by adding a relative to your family. Use this form to include important details about your family members and strengthen your family connections.
                                </Typography>

                                <Box style={{ marginTop: 16, marginBottom: 16, marginLeft: -16, marginRight: -16 }}>
                                    <Divider />
                                </Box>


                                <Box style={{ marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Relative's name <span style={{ color: 'red' }}>*</span></Typography>
                                    <input
                                        type="text"
                                        placeholder="Relative's name"
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                        }}
                                    />
                                </Box>

                                <Box style={{ marginTop: 20, position: 'relative' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Relationship type <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <select
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            paddingRight: '40px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            appearance: 'none',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                            color: '#1C2110'
                                        }}
                                    >
                                        <option value="" disabled selected>Choose the type of family member</option>
                                        <option value="parent">Parent</option>
                                        <option value="sibling">Sibling</option>
                                        <option value="child">Child</option>
                                    </select>
                                    <ExpandMoreIcon
                                        className="expandStyle"
                                    />
                                </Box>


                                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Nickname</Typography>
                                        <input
                                            type="text"
                                            placeholder="Nikename"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>

                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Gender <span style={{ color: 'red' }}>*</span></Typography>
                                        <select
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                paddingRight: '40px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                appearance: 'none',
                                                backgroundColor: '#fff',
                                                cursor: 'pointer',
                                                color: '#1C2110'
                                            }}
                                        >
                                            <option value="" disabled selected>Choose the gender</option>
                                            <option value="parent">Male</option>
                                            <option value="sibling">Female</option>
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: 'absolute',
                                                right: '26px',
                                                top: '68%',
                                                transform: 'translateY(-50%)',
                                                pointerEvents: 'none',
                                                color: '#1C2110',
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Birthday</Typography>
                                        <input
                                            type="date"
                                            placeholder="MM/DD/YYYY"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>

                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Birthday place</Typography>
                                        <input
                                            type="text"
                                            placeholder="Birthday place"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Dad’s name</Typography>
                                        <input
                                            type="text"
                                            placeholder="Dad’s name"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>

                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Mom’s name</Typography>
                                        <input
                                            type="text"
                                            placeholder="Mom’s name"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box style={{ marginTop: 20, gap: '10px' }}>
                                    <Typography style={{ fontWeight: 700, color: "#557C29", fontFamily: "Poppins", fontSize: 14, display: "flex", gap: "10px", alignItems: "center" }}>
                                        <img src={recipeAddIcon} style={{ marginLeft: '8px', cursor: 'pointer', width: 16, height: 16 }} data-test-id="hanldeClickOPenCover" />
                                        Add a family member
                                    </Typography>
                                </Box>

                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110' }}>
                                            Diary
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 500, color: '#1C2110', marginRight: 8 }}>
                                                Private
                                            </Typography>
                                            <CustomizedSwitches />
                                        </div>
                                    </div>

                                    <textarea
                                        placeholder="Describe it in your diary."
                                        rows={2}
                                        cols={40}
                                        maxLength={2000}
                                        onChange={this.handleInputChange}
                                        className="relativeTextAreaStyle"
                                    />

                                    <div style={{ textAlign: 'right', fontSize: 12, fontWeight: 400, fontFamily: "Poppins", color: '#44403C', marginTop: 4 }}>
                                        {this.state.charCount}/2000
                                    </div>
                                </div>

                                <Box style={{ display: 'flex', justifyContent: 'center', gap: 16, marginTop: 20, paddingBottom: 40 }}>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            backgroundColor: '#F8FFE7',
                                            textTransform: 'none',
                                            color: '#557C29',
                                            border: "1px solid #557C29",
                                            width: "153px",
                                            height: 56,
                                            borderRadius: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 700
                                        }}
                                        onClick={this.handleRelativeModalClose}
                                    >

                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#557C29',
                                            textTransform: 'none',
                                            color: '#fff',
                                            border: "1px solid #557C29",
                                            width: "153px",
                                            height: 56,
                                            borderRadius: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 700
                                        }}
                                    >
                                        Add relative
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>


                        <Button style={{ backgroundColor: "black", color: "#fff", marginTop: 20, textTransform: 'none' }} data-test-id="covermomentModalOpen" onClick={this.handleMomentModalOpen}>Add Moment</Button>
                        <Modal
                            open={this.state.momentModal}
                            data-test-id="closeMomentModal"
                            onClose={this.handleMomentModalClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                maxWidth: '700px',
                                width: '100%',
                                maxHeight: '80vh',
                                backgroundColor: 'white',
                                boxShadow: "24",
                                padding: '16px',
                                borderRadius: '8px 8px 32px 8px',
                                overflowY: 'auto'
                            }}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography style={{ fontWeight: 700, fontSize: 24, fontFamily: "Poppins", color: "#000" }}>
                                        Add moment
                                    </Typography>
                                    <Button onClick={this.handleMomentModalClose}><CloseIcon style={{ color: '#44403C' }} /></Button>
                                </Box>

                                <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 400, color: '#57534E', marginTop: 16 }}>
                                    Capture and cherish the important milestones of your life. Use this form to add a memorable moment, whether it's a personal achievement, a special event, or a significant experience that you want to remember and share.
                                </Typography>

                                <Box style={{ marginTop: 16, marginBottom: 16, marginLeft: -16, marginRight: -16 }}>
                                    <Divider />
                                </Box>


                                <Box style={{ marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Moment title <span style={{ color: 'red' }}>*</span></Typography>
                                    <input
                                        type="text"
                                        placeholder="Title of the Moment"
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                        }}
                                    />
                                </Box>

                                <Box style={{ marginTop: 20, position: 'relative' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Moment type <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <select
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            paddingRight: '40px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            appearance: 'none',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                            color: '#1C2110'
                                        }}
                                    >
                                        <option value="" disabled selected>Choose the type of moment</option>
                                        <option value="parent">Parent</option>
                                        <option value="sibling">Sibling</option>
                                        <option value="child">Child</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            right: '10px',
                                            position: 'absolute',
                                            top: '66%',
                                            pointerEvents: 'none',
                                            transform: 'translateY(-50%)',
                                            color: '#1C2110',
                                        }}
                                    />
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Date</Typography>
                                        <input
                                            type="date"
                                            placeholder="MM/DD/YYYY"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>

                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Place</Typography>
                                        <input
                                            type="text"
                                            placeholder="Place"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box style={{ marginTop: 20, position: 'relative' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        People
                                    </Typography>
                                    <select
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            paddingRight: '40px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            appearance: 'none',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                            color: '#1C2110'
                                        }}
                                    >
                                        <option value="" disabled selected>Choose people who you shared this moment with</option>
                                        <option value="parent">Parent</option>
                                        <option value="sibling">Sibling</option>
                                        <option value="child">Child</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '66%',
                                            transform: 'translateY(-50%)',
                                            pointerEvents: 'none',
                                            color: '#1C2110',
                                        }}
                                    />
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Description
                                    </Typography>
                                </Box>

                                <textarea
                                    placeholder="Describe it in your diary."
                                    rows={2}
                                    cols={40}
                                    maxLength={2000}
                                    onChange={this.handleInputChange}
                                    style={{
                                        width: "100%",
                                        marginTop: 8,
                                        borderRadius: 8,
                                        height: 102,
                                        padding: '8px',
                                        resize: 'none',
                                        border: '1px solid #D6D3D1',
                                        color: "#000",
                                        fontSize: 16,
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                    }}
                                />

                                <div style={{ textAlign: 'right', fontSize: 12, fontWeight: 400, fontFamily: "Poppins", color: '#44403C', marginTop: 4 }}>
                                    {this.state.charCount}/2000
                                </div>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Upload photos or documents
                                    </Typography>
                                </Box>

                                <Box
                                    data-test-id="handlrDragTestCase"
                                    onDragOver={this.handleDrag}
                                    onDragLeave={this.handleDragLeave}
                                    onDragEnter={this.handleDrag}
                                    onDrop={this.handleDrop}
                                    style={{
                                        padding: '40px',
                                        position: 'relative',
                                        textAlign: 'center',
                                        border: this.state.dragActive ? '4px dashed #8CA74B' : '4px dashed #D6D3D1',
                                        borderRadius: '8px',
                                        backgroundColor: '#fff',
                                        fontFamily: 'Poppins',
                                        marginTop: 10,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: 16,
                                            color: '#1C2110',
                                            fontWeight: 700,
                                            fontFamily: "Poppins",
                                            marginBottom: 1,
                                        }}
                                    >
                                        Upload images or documents
                                    </Typography>
                                    <Typography
                                        className="formateTextStyle"
                                    >
                                        Formats supported: JPG, PNG, GIF, TIFF, HEIF, PDF
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: '#202020',
                                            fontFamily: "Poppins",
                                            marginTop: 10
                                        }}
                                    >
                                        Drag an image or document here or
                                    </Typography>
                                    <Box
                                        onClick={this.triggerFileInput}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: 10,
                                            gap: '10px',
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={recipeAddIcon} style={{ width: 20, height: 20 }} alt="upload-icon" />
                                        <Typography style={{ fontFamily: "Poppins", fontWeight: 700, fontSize: 16, color: '#557C29' }}>
                                            Browse file to upload
                                        </Typography>
                                    </Box>

                                    <input
                                        id="file-upload"
                                        type="file"
                                        onChange={this.handleFileSelection}
                                        style={{ display: 'none' }}
                                    />

                                    {this.state.selectedFile && (
                                        <Typography
                                            style={{
                                                fontFamily: "Poppins",
                                                marginTop: 10,
                                                fontSize: 14,
                                                color: '#1C2110',
                                            }}
                                        >
                                            Selected file: {this.state.selectedFile.name}
                                        </Typography>
                                    )}
                                </Box>
                                <Box style={{ display: 'flex', gap: "5px", alignItems: 'center', marginTop: 20, marginLeft: '-10px' }}>
                                    <Checkbox style={{ color: "#557C29" }} />
                                    <Typography style={{fontWeight: 700,  fontFamily: "Poppins", fontSize: 16, color: '#8CA74B' }}>
                                        Create album
                                    </Typography>
                                    <img src={iIcon} style={{ height: 20, width: 20, color: '#8CA74B' }} />
                                </Box>
                                <Box style={{ marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Share <span style={{ color: 'red' }}>*</span></Typography>
                                    <input
                                        type="text"
                                        placeholder="Title of the Moment"
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            fontFamily: 'Poppins',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                        }}
                                    />
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: 16, fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Diary
                                    </Typography>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginRight: '-25px' }}>
                                        <Typography style={{ fontFamily: 'Poppins', fontSize: 16, fontWeight: 500, color: '#1C2110', marginRight: 8 }}>
                                            Private
                                        </Typography>
                                        <CustomizedSwitches />
                                    </Box>
                                </Box>

                                <textarea
                                    placeholder="Describe it in your diary."
                                    cols={40}
                                    rows={2}
                                    maxLength={2000}
                                    onChange={this.handleInputChange}
                                    style={{
                                        marginTop: 8,
                                        borderRadius: 8,
                                        width: "100%",
                                        height: 102,
                                        padding: '8px',
                                        resize: 'none',
                                        border: '1px solid #D6D3D1',
                                        fontSize: 16,
                                        color: "#000",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                    }}
                                />

                                <div style={{ textAlign: 'right', fontSize: 12, fontWeight: 400, fontFamily: "Poppins", color: '#44403C', marginTop: 4 }}>
                                    {this.state.charCount}/2000
                                </div>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                                    <Typography className="privateMomentStyle">
                                        Private Moment
                                    </Typography>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginRight: '-25px', marginTop: 15 }}>
                                        <CustomizedSwitches />
                                    </Box>
                                </Box>


                                <Box style={{ marginTop: 16, marginBottom: 16, marginLeft: -16, marginRight: -16 }}>
                                    <Divider />
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'center', gap: 16, marginTop: 20, paddingBottom: 40 }}>
                                    <Button
                                        variant="outlined"
                                        className="cancelButtonStyle"
                                        onClick={this.handleMomentModalClose}
                                    >

                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="momentButtonStyle"
                                      
                                    >
                                        Save moment
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>

                        <Button style={{ backgroundColor: "purple", color: "#fff", marginTop: 20, textTransform: 'none' }} data-test-id="coverRecipeModal" onClick={this.handleRecipesModalOpen}>Add Recipes</Button>
                        <Modal
                            open={this.state.recipesModal}
                            data-test-id="closeRecipeModalTestId"
                            onClose={this.handleRecipesModalClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                maxWidth: '700px',
                                width: '100%',
                                maxHeight: '80vh',
                                backgroundColor: 'white',
                                boxShadow: "24",
                                padding: '16px',
                                borderRadius: '8px 8px 32px 8px',
                                overflowY: 'auto'
                            }}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography style={{ fontWeight: 700, fontSize: 24, fontFamily: "Poppins", color: "#000" }}>
                                        Add recipe
                                    </Typography>
                                    <Button onClick={this.handleRecipesModalClose}><CloseIcon style={{ color: '#44403C' }} /></Button>
                                </Box>

                                <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 400, color: '#57534E', marginTop: 16 }}>
                                    Share your culinary creations by adding a new recipe. Use this form to enter the ingredients, steps, and any special tips for preparing your favorite dish.
                                </Typography>

                                <Box style={{ marginTop: 16, marginBottom: 16, marginLeft: -16, marginRight: -16 }}>
                                    <Divider />
                                </Box>


                                <Box style={{ marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Title <span style={{ color: 'red' }}>*</span></Typography>
                                    <input
                                        type="text"
                                        placeholder="Title of the recipe"
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                        }}
                                    />
                                </Box>

                                <Box style={{ marginTop: 20, position: 'relative' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Recipe’s type <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <select
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            paddingRight: '40px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            appearance: 'none',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                            color: '#1C2110'
                                        }}
                                    >
                                        <option value="" disabled selected>Choose the recipe’s type</option>
                                        <option value="parent">Parent</option>
                                        <option value="sibling">Sibling</option>
                                        <option value="child">Child</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '66%',
                                            transform: 'translateY(-50%)',
                                            pointerEvents: 'none',
                                            color: '#1C2110',
                                        }}
                                    />
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Creator’s name <span style={{ color: 'red' }}>*</span></Typography>
                                        <input
                                            type="text"
                                            placeholder="Creator’s name"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Creation’s date <span style={{ color: 'red' }}>*</span></Typography>
                                        <input
                                            type="date"
                                            placeholder="MM/DD/YYYY"
                                            style={{
                                                width: "100%",
                                                marginTop: 8,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box style={{ marginTop: 20, position: 'relative' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Ingredients  <span style={{ color: 'red' }}>*</span></Typography>
                                    <Box>
                                        <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                            <Toolbar style={{ right: 30 }}>
                                                <IconButton  data-test-id="toggleUpperCaseId" onClick={this.toggleUppercase} color={isUppercase ? 'primary' : 'default'}>
                                                    {/* Custom TT styled text with conditional text transformation */}
                                                    <Typography style={{ textTransform: isUppercase ? 'uppercase' : 'none' }}>
                                                        TT
                                                    </Typography>
                                                </IconButton>
                                                <IconButton data-test-id="toggleBoldCover" onClick={this.toggleBold} color={isBold ? 'primary' : 'default'}>
                                                    <FormatBoldIcon />
                                                </IconButton>
                                                <IconButton data-test-id="toggleItalicCover" onClick={this.toggleItalic} color={isItalic ? 'primary' : 'default'}>
                                                    <FormatItalicIcon />
                                                </IconButton>
                                                <IconButton data-test-id="toggleUnderlineCover" onClick={this.toggleUnderline} color={isUnderline ? 'primary' : 'default'}>
                                                    <FormatUnderlinedIcon />
                                                </IconButton>
                                                <IconButton data-test-id="alignmentTestCase" onClick={() => this.setAlignment('left')} color={alignment === 'left' ? 'primary' : 'default'}>
                                                    <FormatAlignLeftIcon />
                                                </IconButton>
                                                <IconButton data-test-id="alignmentTestCase"  onClick={() => this.setAlignment('center')} color={alignment === 'center' ? 'primary' : 'default'}>
                                                    <FormatAlignCenterIcon />
                                                </IconButton>
                                                <IconButton data-test-id="alignmentTestCase"  onClick={() => this.setAlignment('right')} color={alignment === 'right' ? 'primary' : 'default'}>
                                                    <FormatAlignRightIcon />
                                                </IconButton>
                                            </Toolbar>
                                            <IconButton>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>

                                        <Divider style={{ marginBottom: 20 }} />


                                        <div
                                            contentEditable
                                            style={fontStyle}
                                            suppressContentEditableWarning={true}
                                        >

                                        </div>
                                    </Box>
                                </Box>

                                <Box style={{ position: 'relative',  marginTop: 20 }}>
                                    <Typography style={{ fontWeight: 700, fontSize: 16, fontFamily: "Poppins", color: '#1C2110', marginTop: 16 }}>Instructions <span style={{ color: 'red' }}>*</span></Typography>
                                    <Box>
                                        <Box style={{ justifyContent: "space-between", display: 'flex', alignItems: "center" }}>
                                            <Toolbar style={{ right: 30 }}>
                                                <IconButton onClick={this.toggleUppercase} color={isUppercase ? 'primary' : 'default'}>
                                                    <Typography style={{ textTransform: isUppercase ? 'uppercase' : 'none' }}>
                                                        TT
                                                    </Typography>
                                                </IconButton>
                                                <IconButton onClick={this.toggleBold} color={isBold ? 'primary' : 'default'}>
                                                    <FormatBoldIcon />
                                                </IconButton>
                                                <IconButton onClick={this.toggleItalic} color={isItalic ? 'primary' : 'default'}>
                                                    <FormatItalicIcon />
                                                </IconButton>
                                                <IconButton onClick={this.toggleUnderline} color={isUnderline ? 'primary' : 'default'}>
                                                    <FormatUnderlinedIcon />
                                                </IconButton>
                                                <IconButton onClick={() => this.setAlignment('left')} color={alignment === 'left' ? 'primary' : 'default'}>
                                                    <FormatAlignLeftIcon />
                                                </IconButton>
                                                <IconButton onClick={() => this.setAlignment('center')} color={alignment === 'center' ? 'primary' : 'default'}>
                                                    <FormatAlignCenterIcon />
                                                </IconButton>
                                                <IconButton onClick={() => this.setAlignment('right')} color={alignment === 'right' ? 'primary' : 'default'}>
                                                    <FormatAlignRightIcon />
                                                </IconButton>
                                            </Toolbar>
                                            <IconButton>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>

                                        <Divider style={{ marginBottom: 20 }} />


                                        <div
                                            contentEditable
                                            style={fontStyle}
                                            suppressContentEditableWarning={true}
                                        >

                                        </div>
                                    </Box>
                                </Box>

                                <Typography style={{ fontSize: 16, fontFamily: "Poppins", color: '#1C2110', marginTop: 16, fontWeight: 700 }}>Preparation time</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Hours</Typography>

                                        <Box style={{ position: 'relative', width: '100%', marginTop: 8 }}>
                                            <select
                                                style={{
                                                    width: "100%",
                                                    padding: '20px',
                                                    borderRadius: '8px',
                                                    border: '1px solid #ccc',
                                                    boxShadow: 'none',
                                                    fontSize: '16px',
                                                    outline: 'none',
                                                    fontFamily: 'Poppins',
                                                    backgroundColor: '#fff',
                                                    fontWeight: 400,
                                                    appearance: 'none'
                                                }}
                                            >
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i < 10 ? `0${i}` : i}>
                                                        {i < 10 ? `0${i}` : i}
                                                    </option>
                                                ))}
                                            </select>

                                            <img
                                                src={downDropIcon}
                                                alt="Dropdown Icon"
                                                style={{
                                                    right: '16px',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    pointerEvents: 'none',
                                                    width: '24px',
                                                    transform: 'translateY(-50%)',
                                                    height: '24px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Minutes</Typography>

                                        <Box style={{ position: 'relative', width: '100%', marginTop: 8 }}>
                                            <select
                                                style={{
                                                    width: "100%",
                                                    padding: '20px',
                                                    border: '1px solid #ccc',
                                                    boxShadow: 'none',
                                                    outline: 'none',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    borderRadius: '8px',
                                                    backgroundColor: '#fff',
                                                    appearance: 'none'
                                                }}
                                            >
                                                {Array.from({ length: 60 }, (_, i) => (
                                                    <option key={i} value={i < 10 ? `0${i}` : i}>
                                                        {i < 10 ? `0${i}` : i}
                                                    </option>
                                                ))}
                                            </select>

                                            <img
                                                src={downDropIcon}
                                                alt="Dropdown Icon"
                                                style={{
                                                    position: 'absolute',
                                                    pointerEvents: 'none',
                                                    right: '16px',
                                                    top: '50%',
                                                    width: '24px',
                                                    height: '24px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>



                                <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Cooking time</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Hours</Typography>

                                        <Box style={{ position: 'relative', width: '100%', marginTop: 8 }}>
                                            <select
                                                style={{
                                                    padding: '20px',
                                                    borderRadius: '8px',
                                                    border: '1px solid #ccc',
                                                    width: "100%",
                                                    boxShadow: 'none',
                                                    outline: 'none',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    backgroundColor: '#fff',
                                                    appearance: 'none'
                                                }}
                                            >
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i < 10 ? `0${i}` : i}>
                                                        {i < 10 ? `0${i}` : i}
                                                    </option>
                                                ))}
                                            </select>

                                            <img
                                                src={downDropIcon}
                                                alt="Dropdown Icon"
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    right: '16px',
                                                    width: '24px',
                                                    pointerEvents: 'none',
                                                    height: '24px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ width: '48%' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Minutes</Typography>

                                        <Box style={{ position: 'relative', width: '100%', marginTop: 8 }}>
                                            <select
                                                style={{
                                                    width: "100%",
                                                    padding: '20px',
                                                    borderRadius: '8px',
                                                    boxShadow: 'none',
                                                    outline: 'none',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '16px',
                                                    border: '1px solid #ccc',
                                                    fontWeight: 400,
                                                    backgroundColor: '#fff',
                                                    appearance: 'none'
                                                }}
                                            >
                                                {Array.from({ length: 60 }, (_, i) => (
                                                    <option key={i} value={i < 10 ? `0${i}` : i}>
                                                        {i < 10 ? `0${i}` : i}
                                                    </option>
                                                ))}
                                            </select>

                                            <img
                                                src={downDropIcon}
                                                alt="Dropdown Icon"
                                                style={{
                                                    right: '16px',
                                                    position: 'absolute',
                                                    transform: 'translateY(-50%)',
                                                    top: '50%',
                                                    width: '24px',
                                                    height: '24px',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ marginTop: 20, position: 'relative' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Difficulty level
                                    </Typography>
                                    <select
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            paddingRight: '40px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            appearance: 'none',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                            color: '#1C2110'
                                        }}
                                    >
                                        <option value="parent">Easy</option>
                                        <option value="sibling">Medium</option>
                                        <option value="child">Hard</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '66%',
                                            transform: 'translateY(-50%)',
                                            pointerEvents: 'none',
                                            color: '#1C2110',
                                        }}
                                    />
                                </Box>

                                <Box style={{ marginTop: 20, position: 'relative' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Cuisine
                                    </Typography>
                                    <select
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            paddingRight: '40px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            appearance: 'none',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                            color: '#1C2110'
                                        }}
                                    >
                                        <option value="parent">Maxican</option>
                                        <option value="sibling">Italian</option>
                                        <option value="child">Indian</option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '66%',
                                            transform: 'translateY(-50%)',
                                            pointerEvents: 'none',
                                            color: '#1C2110',
                                        }}
                                    />
                                </Box>

                                <Box style={{ marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>URL link</Typography>
                                    <input
                                        type="text"
                                        placeholder="https://cooking.com/recipe/lemonpie"
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                        }}
                                    />
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Upload images of your recipe
                                    </Typography>
                                </Box>

                                <Box
                                 data-test-id="handlrDragTestCase"
                                    onDragOver={this.handleDrag}
                                    onDragEnter={this.handleDrag}
                                    onDragLeave={this.handleDragLeave}
                                    onDrop={this.handleDrop}
                                    style={{
                                        padding: '40px',
                                        textAlign: 'center',
                                        border: this.state.dragActive ? '4px dashed #8CA74B' : '4px dashed #D6D3D1',
                                        borderRadius: '8px',
                                        backgroundColor: '#fff',
                                        fontFamily: 'Poppins',
                                        marginTop: 10,
                                        position: 'relative',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 700,
                                            fontSize: 16,
                                            color: '#1C2110',
                                            fontFamily: "Poppins",
                                            marginBottom: 1,
                                        }}
                                    >
                                        Upload images or documents
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: '#202020',
                                            marginBottom: 2,
                                            fontFamily: "Poppins",
                                            marginTop: 5
                                        }}
                                    >
                                        Formats supported: JPG, PNG, GIF, TIFF, HEIF, PDF
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: '#202020',
                                            fontFamily: "Poppins",
                                            marginTop: 10
                                        }}
                                    >
                                        Drag an image or document here or
                                    </Typography>
                                    <Box
                                        data-test-id="tiggerFileCover"
                                        onClick={this.triggerFileInput}
                                        style={{
                                            display: 'flex',
                                            gap: '10px',
                                            justifyContent: 'center',
                                            marginTop: 10,
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={recipeAddIcon} style={{ width: 20, height: 20 }} alt="upload-icon" />
                                        <Typography style={{ fontFamily: "Poppins", fontWeight: 700, fontSize: 16, color: '#557C29' }}>
                                            Browse file to upload
                                        </Typography>
                                    </Box>

                                    <input
                                        id="file-upload"
                                        type="file"
                                        style={{ display: 'none' }}
                                        data-test-id="handleFileTestSection"
                                        onChange={this.handleFileSelection}
                                    />

                                    {this.state.selectedFile && (
                                        <Typography
                                            style={{
                                                marginTop: 10,
                                                fontSize: 14,
                                                color: '#1C2110',
                                                fontFamily: "Poppins",
                                            }}
                                        >
                                            Selected file: {this.state.selectedFile.name}
                                        </Typography>
                                    )}
                                </Box>

                                <Box style={{ marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Poppins", fontWeight: 700, color: '#1C2110', marginTop: 16 }}>Share</Typography>
                                    <input
                                        type="text"
                                        placeholder="Add people who you want to share this file"
                                        style={{
                                            width: "100%",
                                            marginTop: 8,
                                            padding: '20px',
                                            borderRadius: '8px',
                                            border: '1px solid #ccc',
                                            boxShadow: 'none',
                                            outline: 'none',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                        }}
                                    />
                                </Box>
                                <Box style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginRight: '-25px', justifyContent: 'space-between' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 500, color: '#1C2110', marginRight: 8 }}>
                                        Private
                                    </Typography>
                                    <CustomizedSwitches />
                                </Box>

                                <Box style={{ marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', marginBottom: 8 }}>
                                        Tags
                                    </Typography>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 8,
                                            padding: '8px',
                                            border: '2px solid #8CA74B',
                                            height: 86,
                                            borderRadius: '8px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {this.state.tags.map((tag) => (
                                            <Chip
                                                key={tag}
                                                label={tag}
                                                onDelete={() => this.handleDelete(tag)}
                                                style={{
                                                    fontFamily: 'Poppins',
                                                    backgroundColor: '#f0f0f0',
                                                    fontWeight: 400,
                                                    width:"122px",
                                                    height: "38px"
                                                }}
                                            />
                                        ))}
                                        <TextField
                                            variant="standard"
                                            value={this.state.inputValue}
                                            data-test-id="handleInputTestCases"
                                            onChange={this.handleInputChangeTags}
                                            onKeyDown={this.handleKeyDown}
                                            placeholder="Add a tag"
                                            InputProps={{
                                                disableUnderline: true,
                                                style: { fontFamily: 'Poppins', fontSize: '16px', fontWeight: 400 },
                                            }}
                                            style={{ flexGrow: 1 }}
                                        />
                                    </Box>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                                    <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Notes
                                    </Typography>
                                </Box>

                                <textarea
                                    placeholder="Add a note to give extra information about your recipe"
                                    rows={2}
                                    cols={40}
                                    maxLength={2000}
                                    date-test-id="textAreaCover"
                                    onChange={this.handleInputChange}
                                    style={{
                                        width: "100%",
                                        marginTop: 8,
                                        borderRadius: 8,
                                        height: 102,
                                        padding: '8px',
                                        resize: 'none',
                                        border: '1px solid #D6D3D1',
                                        color: "#000",
                                        fontSize: 16,
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                    }}
                                />

                                <div style={{ textAlign: 'right', fontSize: 12, fontWeight: 400, fontFamily: "Poppins", color: '#44403C', marginTop: 4 }}>
                                    {this.state.charCount}/500
                                </div>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', marginTop: 16 }}>
                                        Diary
                                    </Typography>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginRight: '-25px' }}>
                                        <Typography style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 500, color: '#1C2110', marginRight: 8 }}>
                                            Private
                                        </Typography>
                                        <CustomizedSwitches />
                                    </Box>
                                </Box>

                                <textarea
                                    placeholder="Describe it in your diary."
                                    rows={2}
                                    cols={40}
                                    maxLength={2000}
                                    onChange={this.handleInputChange}
                                    style={{
                                        width: "100%",
                                        marginTop: 8,
                                        height: 102,
                                        padding: '8px',
                                        resize: 'none',
                                        border: '1px solid #D6D3D1',
                                        color: "#000",
                                        borderRadius: 8,
                                        fontSize: 16,
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                    }}
                                />

                                <div style={{ textAlign: 'right', fontFamily: "Poppins", fontSize: 12, fontWeight: 400, color: '#44403C', marginTop: 4 }}>
                                    {this.state.charCount}/2000
                                </div>
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box style={{ display: 'flex', gap: "10px", alignItems: 'center' }}>
                                        <img src={recipeAddIcon} style={{ width: 20, height: 20 }} />
                                        <Typography style={{ fontFamily: "Poppins", fontSize: 16, fontWeight: 700, color: '#8CA74B' }}>Add a note</Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', gap: "5px", alignItems: 'center', paddingRight:10 }}>
                                        <Checkbox />
                                        <Typography style={{ fontFamily: "Poppins", fontWeight: 700, fontSize: 16, color: '#8CA74B' }}>Mark as private</Typography>
                                        <img src={iIcon} style={{ color: '#8CA74B', width: 20, height: 20 }} />
                                    </Box>
                                </Box>

                                <Box style={{ marginTop: 16, marginBottom: 16, marginLeft: -16, marginRight: -16 }}>
                                    <Divider />
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'center', gap: 16, marginTop: 20, paddingBottom: 40 }}>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            backgroundColor: '#F8FFE7',
                                            textTransform: 'none',
                                            color: '#557C29',
                                            border: "1px solid #557C29",
                                            height: 56,
                                            borderRadius: 8,
                                            width: "153px",
                                            fontFamily: "Poppins",
                                            fontWeight: 700
                                        }}
                                        onClick={this.handleRecipesModalClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#557C29',
                                            textTransform: 'none',
                                            border: "1px solid #557C29",
                                            width: "153px",
                                            height: 56,
                                            borderRadius: 8,
                                            color: '#fff',
                                            fontFamily: "Poppins",
                                            fontWeight: 700
                                        }}
                                    >
                                        Save recipe
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>

                    </Box>
                </Box>
            </MemoriesDashboardStyle>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const MemoriesDashboardStyle = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    "& .MainContent": {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    "& .Content": {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginLeft: '20px',
        alignItems: 'center'
    },
    "& .sharingTitle": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "30px",
        fontWeight: 700,
        letterSpacing: '-0.5%'
    },
    "& .modalStyle": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        backgroundColor: "#fff",
        border: "2px solid #000",
        boxShadow: "24px",
        padding: "16px"
    },
    "& .modalHeader": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .expandStyle":{
        position: 'absolute',
        right: '10px',
        top: '66%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        color: '#1C2110',
    },
    "& .relativeTextAreaStyle":{
        width: "100%",
        marginTop: 8,
        borderRadius: 8,
        height: 102,
        padding: '8px',
        resize: 'none',
        border: '1px solid #D6D3D1',
        color: "#000",
        fontSize: 16,
        fontFamily: "Poppins",
        fontWeight: 400,
    },
    "& .formateTextStyle":{
        fontWeight: 400,
        fontSize: 14,
        color: '#202020',
        marginBottom: 2,
        fontFamily: "Poppins",
        marginTop: 5
    },
    "& .privateMomentStyle":{
        fontSize: 16, 
        fontFamily: 'Poppins', 
        fontWeight: 400, 
        color: '#1C2110', 
        marginTop: 16 
    },
    "& .cancelButtonStyle":{
        backgroundColor: '#F8FFE7',
        textTransform: 'none',
        color: '#557C29',
        border: "1px solid #557C29",
        width: "153px",
        height: 56,
        borderRadius: 8,
        fontFamily: "Poppins",
        fontWeight: 700
    },
    "& .momentButtonStyle":{
        backgroundColor: '#557C29',
        textTransform: 'none',
        color: '#fff',
        border: "1px solid #557C29",
        width: "153px",
        height: 56,
        borderRadius: 8,
        fontFamily: "Poppins",
        fontWeight: 700
    }

});
// Customizable Area End
