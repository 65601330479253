import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { Recipes, recipesImg, storeImg, userProfile, Storage } from "./assets";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ShareIcon from "@mui/icons-material/Share";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';

interface MenuItem {
  text: string;
  subItems: string[];
  icon: React.ReactNode;
  root:string;
}


const menuItems: MenuItem[] = [
  { text: "Favorites", subItems: [], icon: <StarOutlineIcon />,root:"" },
  { text: "Photos", subItems: [], icon: <CameraAltOutlinedIcon /> ,root:""},
  { text: "People", subItems: ["Family", "Friends"], icon: <PeopleOutlinedIcon />,root:"" },
  { text: "Moments", subItems: ["Places", "Memories"], icon: <SentimentVerySatisfiedOutlinedIcon />,root:"" },
  { text: "Family Tree", subItems: [], icon: <AccountTreeOutlinedIcon />,root:"" },
  { text: "Recipes", subItems: [], icon: null,root:"" },
  { text: "Sharing", subItems: [], icon: <ShareIcon />,root:"SharingDashboard" },
  { text: "Storage", subItems: [], icon: null,root:"" },
  { text: "Trash", subItems: [], icon: <DeleteOutlineIcon />,root:"TrashFile" },
];

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";
import SideDrawer from "../../../components/src/SideDrawer";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  mainItemStyle = (text: string): React.CSSProperties => ({
    height: "40px",
    color: (this.state.selectedItem === text || this.state.hoveredItem === text) ? "#fff" : "#1C2110",
    backgroundColor: (this.state.selectedItem === text || this.state.hoveredItem === text) ? "#557C29" : "white",
    borderRadius: (this.state.selectedItem === text || this.state.hoveredItem === text) ? "4px" : "0px",
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {selectedItem, hoveredItem, showPeopleSubItems, showMomentsSubItems} = this.state;

    return (
      <Box sx={{
        width: 250, background: "white", boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)", height: "100vh", '@media (max-width:950px)': {
          display: `${this.props.isFromHeader ? 'block' : 'none'}`,
        },
      }}  >
        <List style={{ padding: '20px' }}>
        {menuItems.map(({ text, subItems, icon,root }) => (
            <React.Fragment key={text}>
              <ListItem disablePadding sx={{ padding: "0px" }}>
                <ListItemButton
                data-test-id = "nav-item-link"
                onClick={(event) => this.handleItemClick(text,root)}
                  onMouseEnter={() => this.handleItemHover(text)}
                  onMouseLeave={this.handleMouseLeave}
                  sx={{
                    ...this.mainItemStyle(text),
                    marginTop: "12px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    "&:hover": {
                      backgroundColor: "#557C29",
                      opacity: 1,
                    height:'40px',
                      color: "#fff !important",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: (selectedItem === text || hoveredItem === text) ? "#fff" : "#1C2110" }}>
                    {text === "Recipes" && (
                      <img
                        src={(selectedItem === "Recipes" || hoveredItem === "Recipes") ? Recipes : recipesImg}
                        style={{ color: "#1C2110" }}
                        alt="Recipes Icon"
                      />
                    )}
                    {text === "Storage" && (
                      <img
                        src={(selectedItem === "Storage" || hoveredItem === "Storage") ? Storage : storeImg}
                        style={{ color: "#1C2110" }}
                        alt="Recipes Icon"
                      />
                    )}
                    {icon}
                  </ListItemIcon>
                  <Typography style={{ marginLeft: '-15px' }}
                    variant="body1"
                    sx={{
                      fontSize: (selectedItem === text || hoveredItem === text) ? 16 : 14,
                      fontWeight: (selectedItem === text || hoveredItem === text) ? 700 : 400,
                      fontFamily: "Poppins, sans-serif",
                      "&:hover": {
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#fff !important",
                      },
                    }}
                  >
                    {text}
                  </Typography>
                </ListItemButton>
              </ListItem>
              {subItems.length > 0 &&
                ((text === "People" && showPeopleSubItems) ||
                  (text === "Moments" && showMomentsSubItems)) && (
                  <List>
                    {subItems.map((subText) => (
                      <ListItem key={subText} disablePadding sx={{padding: "0px"}}>
                        <ListItemButton
                          onClick={() => this.handleItemClick(subText, root)}
                          sx={{
                            borderLeft: "1px solid #fff",
                            color: (selectedItem === subText) ? "#fff" : "#557C29",
                            marginLeft: "35px",
                            height: "40px",
                            borderRadius: (selectedItem === subText) ? "4px" : "0px",
                            backgroundColor: (selectedItem === subText) ? "#557C29" : "white",
                            "&:hover": {
                              backgroundColor: (selectedItem === subText) ? "#557C29" : "white",
                              borderRadius: "4px",
                            },
                          }}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: (selectedItem === subText) ? 16 : 14,
                              fontWeight: (selectedItem === subText) ? 700 : 400,
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {subText}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
};
// Customizable Area End
